import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from '../../styles/mobile/carousel-item.scss';

class MobileCarouselItem extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    image: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    response: PropTypes.object.isRequired,
  }

  render() {
    const {
      image, quote, response, onItemClick,
    } = this.props;
    return (
      <div className={css.container}>
        <img alt="SLA home" src={`${image}`} className={css.img} />
        <div className={css.middle}>
          <div className={css.text}>{quote}</div>
          <br />
          {response && <div className={css.seevoicebtn} onClick={() => onItemClick(response)}>See Voice</div>}
        </div>
      </div>
    );
  }
}
export default MobileCarouselItem;
