/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import css from '../../styles/mobile/image-gallery.scss';
import MobileCarouselItem from './MobileCarouselItemComponent';

import { getRandomResponses } from '../../actions/imageCarouselComponent';

import image1 from '../../resources/home-1.png';
import image2 from '../../resources/home-2.png';
import image3 from '../../resources/home-3.png';
import image4 from '../../resources/home-4.png';
import image5 from '../../resources/home-5.png';
import image6 from '../../resources/home-6.png';
import image7 from '../../resources/home-7.png';
import image8 from '../../resources/home-8.png';

// Incase our api returns a 404 we will use stored quotes
import imagesAndQuotesFromFile from '../../resources/imagesAndQuotes';

class MobileImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesAndQuotes: [],
      returned404: false,
    };
    this.populateStateQuotes = this.populateStateQuotes.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  componentDidMount() {
    this.populateStateQuotes();
  }

  onItemClick(response) {
    const { theme, id } = response;
    window.location.assign(`/voices?id=${id}&theme=${theme}`);
  }

  populateStateQuotes = async () => {
    await this.props.dispatch(getRandomResponses());
    const { randomResponses } = this.props;
    if (randomResponses.length !== 0) {
      this.setState({
        imagesAndQuotes: [
          { image: image1, response: randomResponses[0] },
          { image: image2, response: randomResponses[1] },
          { image: image3, response: randomResponses[2] },
          { image: image4, response: randomResponses[3] },
          { image: image5, response: randomResponses[4] },
          { image: image6, response: randomResponses[5] },
          { image: image7, response: randomResponses[6] },
          { image: image8, response: randomResponses[7] },
        ],
        returned404: false,
      });
    } else {
      this.setState({
        imagesAndQuotes: imagesAndQuotesFromFile,
        returned404: true,
      });
    }
  }

  render() {
    const { imagesAndQuotes, returned404 } = this.state;
    let carouselItems;
    if (returned404) {
      carouselItems = imagesAndQuotes.map(({ image, quote }) => (
        <MobileCarouselItem
          image={image}
          quote={quote}
          response={null}
        />
      ));
    } else {
      carouselItems = imagesAndQuotes.map(({ image, response }) => {
        // Filtering to find the voice question, returning its answer
        // Random id can return null object, setting text = '' prevents error
        let voiceText = (response == null || response.answers == null)
          ? '' : response.answers.filter(answer => answer.question === 'Voice');
        voiceText = (voiceText.length > 0) ? voiceText[0].answer : '';
        let quote;
        // Check to ensure text does not overflow
        // css ellipse not a good option here
        if (voiceText.length < 300) {
          quote = voiceText;
        } else {
          quote = voiceText.slice(0, 300);
          quote += '...';
        }
        return <MobileCarouselItem image={image} quote={quote} response={response} onItemClick={this.onItemClick} />;
      });
    }

    return (
      <div className={css.gallery}>
        {carouselItems.map(item => (
          <div>
            {item}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  randomResponses: state.imageCarouselComponent.randomResponses,
});

export default connect(mapStateToProps)(MobileImageGallery);
