/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import css from '../../styles/mobile/landing.scss';
import HomeImage from '../../resources/home-1.png';
import Down from '../../resources/down.png';

class MobileLanding extends Component {
  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  executeScroll = () => this.myRef.current.scrollIntoView({ behavior: 'smooth' });

  render() {
    return (
      <div className={css.landing} style={{ backgroundImage: `url(${HomeImage})` }}>
        <div className={css.landingGrad} />
        <div className={css.landingContent}>
          <div className={css.contentHeading}>
            This site provides a glimpse into the lives of tenants in South Los Angeles through their own words.
          </div>
          <div className={css.contentSubheading}>
            We hope the voices and images that follow amplify their experiences and motivate policy interventions that remedy past and present harms. We need to work together with our neighbors and our politicians to make a different future possible.
          </div>
          <div className={css.contentExplore} onClick={this.executeScroll} ref={this.myRef}>
            <div>Explore Voices</div>
            <div><img alt="Down array" className={css.downIcon} src={Down} /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileLanding;
