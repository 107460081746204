import image1 from '../resources/home-1.png';
import image2 from '../resources/home-2.png';
import image3 from '../resources/home-3.png';
import image4 from '../resources/home-4.png';
import image5 from '../resources/home-5.png';
import image6 from '../resources/home-6.png';
import image7 from '../resources/home-7.png';
import image8 from '../resources/home-8.png';

const imagesAndQuotes = [
{image:image1, quote:"“I know my roots here. I got faith in my people, I know that one day we will prevail and I want to be here when it happens.”"}, 
{image:image2, quote:"“Land is power. Just being able to purchase my own home and create a space where other people can share in the community is a goal.”"}, 
{image:image3, quote:"“You want to go up already, you want to push me out, but I've been here and I'm going to stay here. We're going to do what it takes to stay here, because it's not fair that you want to push us out and then fix it up.”"}, 
{image:image4, quote:"“I'm fortunate enough that some of my family members live on the same block as me. We're pretty close. There could be family issues, but we're always there for each other...It doesn't necessarily need to be financially. It could be like, 'Can I just come over and stay awhile with you, because I need to get out of this situation?'..They are always there for you.”"}, 
{image:image5, quote:"“I'm scared everyday, because I don't know when my owner has decided to say, 'Hey, I need to get more money for this property.'”"}, 
{image:image6, quote:"“We feel trapped and we feel are hands are tied. It's distressing to see that the end of the month is coming and you're only working to pay the rent.”"}, 
{image:image7, quote:"“I'm not against all of the upgrades in the hood, it's ok if you come in and try to help people in the community, but it's not ok if you come in and try to push us out.”"}, 
{image:image8, quote:"“I am a hustler and survivor to pay rent.”"} ];

export default imagesAndQuotes;