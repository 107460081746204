import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import image from '../resources/home-6.png';
import css from '../styles/mission-statement.scss';
import arrow from '../resources/arrow.png';

class MissionStatement extends Component {
    render() {
        const scrollToBottom = () => {
            var height = window.innerHeight + 70;
            window.scroll({
                left: 0,
                top: height,
                behavior: "smooth"
            });
        }

        return (
            <div>
                <Parallax
                    strength={500}
                    bgImage={image}
                    renderLayer={(percentage) => (
                        <div>
                            <div
                                className={css.parallax}
                                style={{opacity: (1/percentage*1.5)-.5,}}
                            />
                        </div>
                    )}
                >
                    <div className={css.divWrapper}>
                        <div className={css.mission}>This site provides a glimpse into the lives of tenants in South Los Angeles through their own words.</div>
                        <div className={css.descrip}>We hope the voices and images that follow amplify their experiences and motivate policy interventions that remedy past and present harms. We need to work together with our neighbors and our politicians to make a different future possible.</div>
                        <div className={css.exploreWrapper} onClick={scrollToBottom}>
                            <div className={css.explore}>Explore Voices</div>
                            <div className={css.arrow}>
                                <img src={arrow}
                                    alt="arrow"
                                    className={css.arrowimage}
                                />
                            </div>
                        </div>
                    </div>
                </Parallax>
            </div>
        );
    }
}

export default MissionStatement;
