import React, { Component, UseRef } from 'react';
import { isMobile } from 'react-device-detect';
import Footer from '../components/FooterComponent';
import ImageCarousel from '../components/ImageCarouselComponent';
import MissionStatement from '../components/MissionStatementComponent';
import MobileImageGallery from '../components/mobile/MobileImageCarouselComponent';
import MobileLanding from '../components/mobile/MobileLandingComponent';
import Navbar from '../components/NavbarComponent';
import css from '../styles/index.scss';

class Index extends Component {
  render() {
    return (
      <div className={css.body}>
        <div>
          {
          isMobile
            && (
              <div>
                <div className={css.header}>
                  <Navbar mobile={true} />
                </div>
                <div className={css.content}>
                  <MobileLanding />
                  <br />
                  <MobileImageGallery />
                  <br />
                  <Footer admin={false} mobile={true} />
                </div>
              </div>
            )
          }
        </div>
        <div>
          {
            !isMobile && (
              <div>
                <Navbar mobile={false} />
                <br />
                <MissionStatement />
                <br />
                <ImageCarousel />
                <br />
                <Footer admin={false} mobile={false}/>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Index;
