/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import desktopcss from '../styles/nav.scss';
import mobilecss from '../styles/mobile/nav.scss';
import MenuItems from './MenuItems';
import XIcon from '../resources/x.png';
import BarsIcon from '../resources/bars.png';
import MenuLogoX from '../resources/x.png';
import MenuLogoBars from '../resources/bars.png';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  render() {
    const { mobile } = this.props;
    const css = mobile ? mobilecss : desktopcss;

    if (mobile) { // mobile
      return (
        <div className={css.nav}>
          <div className={css.navMenu} style={{ display: this.state.open ? 'flex' : 'none' }}>
            {MenuItems.map(item => (
              <a key={item.url} href={item.url} className={css.navLink}>
                {item.title}
              </a>
            ))}
            <div className={css.addVoice}>
              <a href="/add-your-voice" className={css.addVoiceLink}>Add Your Voice</a>
            </div>
          </div>
          <div className={css.navbar}>
            <div className={css.navbarLogo} onClick={() => {window.location.href = "/"}}>Affordable South LA</div>
            <div className={css.menuLogo} onClick={this.handleClick}>
              <img alt="Menu Logo" className={css.menuLogoIcon} src={this.state.open ? (MenuLogoX) : (MenuLogoBars)} />
            </div>
          </div>
        </div>
      );
    }
    else { // desktop
      return (
        <div>
          <div className={css.navbar}>
            <div className={css.navbarLogo} onClick={() => {window.location.href = "/"}}>Affordable South LA</div>
            <div className={css.navbarMenuBtn} onClick={this.handleClick}>
              { this.state.open
                ? (<img src={XIcon} className={css.navbarMenuIcon} alt="Menu close" />)
                : (<img src={BarsIcon} className={css.navbarMenuIcon} alt="Menu open" />)
              }
            </div>
            <div className={this.state.open ? css.navbarMenu : css.navOptions}>
              {MenuItems.map(item => (
                <a key={item.url} href={item.url} className={css.navLink}>
                  {item.title}
                </a>
              ))}
              <div className={css.addVoice}>
                <a href="/add-your-voice" className={css.addVoiceLink}>
                  Add Your Voice
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Navbar;
