/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Carousel, { slidesToShowPlugin, slidesToScrollPlugin } from '@brainhubeu/react-carousel';
import '../styles/image-carousel.css';
import CarouselItem from './CarouselItemComponent';

import { getRandomResponses } from '../actions/imageCarouselComponent';

import image1 from '../resources/home-1.png';
import image2 from '../resources/home-2.png';
import image3 from '../resources/home-3.png';
import image4 from '../resources/home-4.png';
import image5 from '../resources/home-5.png';
import image6 from '../resources/home-6.png';
import image7 from '../resources/home-7.png';
import image8 from '../resources/home-8.png';

// Incase our api returns a 404 we will use stored quotes
import imagesAndQuotesFromFile from '../resources/imagesAndQuotes';

let windowWidth;

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesAndQuotes: [],
      returned404: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.populateStateQuotes = this.populateStateQuotes.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  componentDidMount() {
    windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.populateStateQuotes();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onItemClick(response) {
    if (response == null) {
      return;
    }
    const theme = response.theme;
    const id = response.id;
    window.location.assign(`/voices?id=${id}&theme=${theme}`);
  }

  populateStateQuotes = async () => {
    await this.props.dispatch(getRandomResponses());
    const { randomResponses } = this.props;
    if (randomResponses.length !== 0) {
      this.setState({
        imagesAndQuotes: [
          { image: image1, response: randomResponses[0] },
          { image: image2, response: randomResponses[1] },
          { image: image3, response: randomResponses[2] },
          { image: image4, response: randomResponses[3] },
          { image: image5, response: randomResponses[4] },
          { image: image6, response: randomResponses[5] },
          { image: image7, response: randomResponses[6] },
          { image: image8, response: randomResponses[7] },
        ],
        returned404: false,
      });
    } else {
      this.setState({
        imagesAndQuotes: imagesAndQuotesFromFile,
        returned404: true,
      });
    }
  }

  handleResize() {
    if (windowWidth != window.innerWidth) {
      location.reload();
      windowWidth = window.innerWidth;
    }
  }

  render() {
    const { imagesAndQuotes, returned404 } = this.state;
    let carouselItems;
    if (returned404) {
      carouselItems = imagesAndQuotes.map(({ image, quote }) => (
        <CarouselItem
          image={image}
          quote={quote}
          response={null}
          onItemClick={this.onItemClick}
        />
      ));
    } else {
      carouselItems = imagesAndQuotes.map(({ image, response }) => {
        // Filtering to find the voice question, returning its answer
        // Random id can return null object, setting text = '' prevents error
        let voiceText = (response == null || response.answers == null)
          ? '' : response.answers.filter(answer => answer.question === 'Voice');
        voiceText = (voiceText.length > 0) ? voiceText[0].answer : '';
        let quote;
        // Check to ensure text does not overflow
        // css ellipse not a good option here
        if (voiceText.length < 300) {
          quote = voiceText;
        } else {
          quote = voiceText.slice(0, 300);
          quote += '...';
        }
        return <CarouselItem image={image} quote={quote} response={response} onItemClick={this.onItemClick} />;
      });
    }
    if (typeof window !== "undefined") {
      if(window.innerWidth > 1200){
        return (
          <Carousel arrows infinite slidesPerPage={3}>
            {carouselItems}
          </Carousel>
        );
      }
      else if(window.innerWidth > 780){
        return (
          <Carousel arrows infinite slidesPerPage={2}>
            {carouselItems}
          </Carousel>
        );
      }
      else{
        return (
          <Carousel arrows infinite slidesPerPage={1}>
            {carouselItems}
          </Carousel>
        );
      }
    }
    else{
      return(<div />)
    }
  }
}

const mapStateToProps = state => ({
  randomResponses: state.imageCarouselComponent.randomResponses,
});

export default connect(mapStateToProps)(ImageCarousel);
