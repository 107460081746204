import React, { Component } from 'react';
import css from '../styles/footer.scss';
import mobilecss from '../styles/mobile/footer.scss';

const FooterItems = [
  {
    heading: 'Voices',
    subheadings:
    [
      {
        title: 'Community Voices',
        url: '/voices',
      },
      {
        title: 'Different Futures',
        url: '/voices',
      },
    ],
  },
  {
    heading: 'Resources',
    subheadings:
    [
      {
        title: 'Services',
        url: '/research-campaigns',
      },
      {
        title: 'Research',
        url: '/research-campaigns',
      },
      {
        title: 'Campaigns',
        url: '/research-campaigns',
      },
    ],
  },
  {
    heading: 'About',
    subheadings:
    [
      {
        title: 'Get to know us',
        url: '/about-the-project',
      },
    ],
    heading2: 'Landscapes',
    subheadings2:
    [
      {
        title: 'View Images',
        url: '/landscapes-map',
      },
    ],
  },
  {
    heading: 'More',
    subheadings:
    [
      {
        title: 'Contact Us',
        url: '/contact-us',
      },
      {
        title: 'Admin Sign In',
        url: '/login',
      },
    ],
  },
];

const AdminFooterItems = [
  {
    heading: 'Admin',
    subheadings:
    [
      {
        title: 'Review Submissions',
        url: '/admin/voices',
      },
      {
        title: 'Edit Research & Campaigns',
        url: '/admin/resources',
      },
      {
        title: 'View Feedback',
        url: '/admin/contact',
      },
      {
        title: 'Edit Admin',
        url: '/admin/edit-admin',
      },
    ],
  },
  {
    heading: 'Voices',
    subheadings:
    [
      {
        title: 'Community Voices',
        url: '/',
      },
      {
        title: 'Different Futures',
        url: '/',
      },
    ],
  },
  {
    heading: 'Resources',
    subheadings:
    [
      {
        title: 'Services',
        url: '/',
      },
      {
        title: 'Research',
        url: '/',
      },
      {
        title: 'Campaigns',
        url: '/',
      },
    ],
  },
  {
    heading: 'About',
    subheadings:
    [
      {
        title: 'Get to know us',
        url: '/about-the-project',
      },
    ],
    heading2: 'Landscapes',
    subheadings2:
    [
      {
        title: 'View Images',
        url: '/landscapes-map',
      },
    ],
  },
];

class Footer extends Component {
  render() {
    const { admin, logout, mobile } = this.props;
    const items = admin ? AdminFooterItems : FooterItems;
    const stylesheet = mobile ? mobilecss : css;
    return (
      <div className={stylesheet.footer}>
        <div className={stylesheet.container}>
          {!mobile && (
            <div className={stylesheet.category}>
              <div className={stylesheet.title}>Affordable South LA</div>
              {!admin && <div className={stylesheet.button} onClick={() => { window.location.href = '/add-your-voice'; }}>Add Your Voice</div>}
              {admin && <div className={stylesheet.button} onClick={logout}>Logout</div>}
            </div>
          )}
          <div className={stylesheet.linkContainer}>
            {items.map(category => (
              <div key={category.heading}>
                <div className={stylesheet.category}>
                  <div className={stylesheet.heading}>
                    {category.heading}
                  </div>
                  {category.subheadings.map(link => (
                    <div key={`${link.url}_${link.title}`} className={stylesheet.list_unstyled}>
                      <a href={link.url} className={stylesheet.navLink}>{link.title}</a>
                    </div>
                  ))}
                  {!mobile && category.heading2 && (
                  <>
                    <div className={stylesheet.heading}>
                      {category.heading2}
                    </div>
                    {category.subheadings2.map(link => (
                      <div key={`${link.url}_${link.title}`} className={stylesheet.list_unstyled}>
                        <a href={link.url} className={stylesheet.navLink}>{link.title}</a>
                      </div>
                    ))}
                  </>
                  )}
                </div>
                {mobile && category.heading2 && (
                  <div key={category.heading2} className={stylesheet.category}>
                    <div className={stylesheet.heading}>
                      {category.heading2}
                    </div>
                    {category.subheadings2.map(link => (
                      <div key={link.url} className={stylesheet.list_unstyled}>
                        <a href={link.url} className={stylesheet.navLink}>{link.title}</a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          {mobile && <div className={stylesheet.button} onClick={() => { window.location.href = '/add-your-voice'; }}>Add Your Voice</div>}
        </div>

        <div className={css.copyright}>
          Copyright &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Affordable South LA
        </div>
      </div>
    );
  }
}

export default Footer;
