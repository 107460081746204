const MenuItems = [
  {
    title: 'Voices',
    url: '/voices',
  },
  {
    title: 'Resources',
    url: '/research-campaigns',
  },
  {
    title: 'About',
    url: '/about-the-project',
  },
  {
    title: 'Landscapes',
    url: '/landscapes-map',
  },
];

export default MenuItems;
