/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from '../styles/image-carousel.scss';

class CarouselItem extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    image: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    onItemClick: PropTypes.func.isRequired,
    response: PropTypes.object.isRequired,
  }

  render() {
    const {
      image, quote, response, onItemClick,
    } = this.props;
    return (
      <div className={css.container} onClick={() => { onItemClick(response); }}>
        <img src={`${image}`} className={css.img} />
        <div className={css.middle}>
          <div className={css.text}>{quote}</div>
        </div>
      </div>
    );
  }
}
export default CarouselItem;
